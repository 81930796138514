/**
 * ww-Blank stylehsheet
 *
 * @author Andreas Glimbrant, Johan Palmfjord, Joel Gustafsson
 * @url http://www.wasabiweb.se
 * @copyright Wasabi Web AB
 * @version 1.0
 */

 .social-time-since {
	display: none;
}